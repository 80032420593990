.project__container {
  display: grid;
  grid-template-columns: repeat(4, 350px);
  column-gap: 3rem;
  justify-content: center;
  width: 300px;
}

.card__container {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
  /* width: 500px; */
  /* height: 450px; */
}

.project__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: 1.5rem;
}

.project__description {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
  display: list-item;
}

.description {
  list-style-type: disc;
  padding-left: 1rem;
}

.swiper {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .swiper-slide {
    width: 400px;
    border-radius: 1.25rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
}
